import { createApp, defineAsyncComponent } from "vue";

import SvgVue from 'svg-vue3'
import VueLazyLoad from 'vue3-lazyload'
const VueScrollTo = require('vue-scrollto')

const app = createApp({
  components: {
    Slider: defineAsyncComponent(() => import('./common/Slider.vue')),
    SocialIcons: defineAsyncComponent(() => import('./common/SocialMediaIcons.vue')),
    PhotoGallery: defineAsyncComponent(() => import('./blocks/PhotoGallery.vue')),
    FoodMenu: defineAsyncComponent(() => import('./common/Menu.vue')),
    InstaGallery: defineAsyncComponent(() => import('./common/InstaGallery.vue')),
    ContactForm: defineAsyncComponent(() => import('./common/ContactForm.vue')),
  },

  data: () => ({
    openMenu: '',
    scrollPosition: 0,
  }),

  computed: {
    mobileMenu () {
      return this.openMenu === 'mobileMenu'
    },
    isScrolling () {
      return this.scrollPosition > 0
    },
  },

  mounted () {
    if (window.location.hash.substring(0, 1) === "#") {
      this.scrollTo(window.location.hash, -73)
    }
  },

  methods: {
    toggleMenu (menu) {
      this.openMenu = (this.openMenu != menu ? menu : '')
    },

    handleScroll (event) {
      this.scrollPosition = window.pageYOffset != undefined ? window.pageYOffset : window.scrollY
    },

    scrollTo ( to, duration = 300, offset = -73) {
      VueScrollTo.scrollTo(to, duration, {
        easing: 'ease-out',
        offset: offset,
        force: true,
        cancelable: true,
      })
    },

  },

  created() {
    window.addEventListener("scroll", this.handleScroll);
  },

  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },

})

app.use(VueLazyLoad)
app.use(SvgVue, {
  svgPath: 'resources/svg',
  extract: false,
  svgoSettings: [
      { removeTitle: true },
      { removeViewBox: false },
      { removeDimensions: true }
  ]
})

app.mount("#app")
